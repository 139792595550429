import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Segment, Button, Divider } from "semantic-ui-react";
import { addTrust, fetchTrusts } from "../../../actions";
import _ from "lodash";

const Index = ({ trusts }) => {
  const dispatch = useDispatch();
  const trusts_in_redux = useSelector((state) => state.trusts);
  const [trust_selected, update_trust_selected] = useState(null);

  useEffect(() => {
    dispatch(fetchTrusts());
  }, []);

  // data functions

  function importTrust() {
    console.log("importing trust:" + trust_selected.name);
    console.log(trust_selected);

    const params = { ...trust_selected };
    delete params.__typename;
    delete params.createdAt;
    delete params.updatedAt;

    console.log(params);
    dispatch(addTrust({ Item: params }));
  }

  // Render return functions
  function returnTrustList() {
    return trusts.map((trust) => {
      if (_.find(trusts_in_redux, { id: trust.id })) {
        return (
          <div
            style={{ cursor: "pointer", color: "green" }}
            //   onClick={() => update_trust_selected(trust)}
          >
            {trust.name + " " + "( Alredy Imported )"}
          </div>
        );
      }
      return (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => update_trust_selected(trust)}
        >
          {trust.name}
        </div>
      );
    });
  }

  function returnTrustSelected() {
    if (trust_selected !== null) {
      return (
        <Segment secondary>
          <h3>Trust Selected: </h3>
          <div>{trust_selected.name}</div>
          <Button primary onClick={() => importTrust()}>
            Import Trust
          </Button>
        </Segment>
      );
    }
    return (
      <Segment secondary>
        <h3>Please select a trust</h3>
      </Segment>
    );
  }
  return (
    <div>
      <div>{returnTrustList()}</div>
      <Divider />
      <div>{returnTrustSelected()}</div>
    </div>
  );
};

export default Index;
