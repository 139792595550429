import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrusts } from "../../actions";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import {
  listUsers,
  addUser,
  listUsersInGroups,
  disableUser,
  enableUser,
  listGroupsForUser,
} from "../../functions/admin/users";
import UserTable from "./UserTable";
import AddUser from "./addUser";
const Index = () => {
  const dispatch = useDispatch();

  const [loading, update_loading] = useState(true);
  const [add_user_modal, update_add_user_modal] = useState(false);
  const [users, update_users] = useState([]);
  const trusts = useSelector((state) => state.trusts);

  useEffect(() => {
    try {
      //const result = listCognitoUsers("list", "{}");
      dispatch(fetchTrusts());
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  async function fetchData() {
    var user_list = [];
    try {
      var initial_user_list = await listUsers();
      initial_user_list.map(async (u, index) => {
        const groups_for_user = await listGroupsForUser(u);
        var group_list = [];
        groups_for_user.Groups.map((g) => {
          group_list.push(g.GroupName);
        });
        initial_user_list[index].groups = group_list;
      });
      user_list = [...initial_user_list];
    } catch (error) {}

    console.log("final list");
    console.log(user_list);
    update_users(user_list);
    update_loading(false);
  }
  if (loading) {
    return (
      <div>
        <Segment style={{ height: 100 }}>
          <Dimmer active inverted>
            <Loader size="medium">Loading Users</Loader>
          </Dimmer>
        </Segment>
      </div>
    );
  }

  return (
    <div>
      {" "}
      <UserTable
        users={users}
        // update_add_user_modal={update_add_user_modal}
        // practice_list={practices}
        // //userFunction={listCognitoUsers}
        // disableUser={disableUser}
        // enableUser={enableUser}
        // listGroupsForUser={listGroupsForUser}
      />
      <AddUser
        add_user={addUser}
        add_user_modal={add_user_modal}
        update_add_user_modal={update_add_user_modal}
        trusts={trusts}
        // update_practice_details={update_practice_details}
      />
      {/* <div onClick={() => fetchData()}>Get users</div> */}
    </div>
  );
};

export default Index;
