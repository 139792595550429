import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";

import _ from "lodash";
import {
  dataImporter,
  getPage,
  sectionsSectionId,
} from "../../graphql/queries";
import {
  Divider,
  Dropdown,
  Grid,
  Button,
  Segment,
  Menu,
  Image,
  Header,
  Modal,
  Icon,
  Accordion,
} from "semantic-ui-react";
import {
  fetchAllTiles,
  fetchAllPages,
  fetchAllSections,
  fetchAllPathways,
  fetchAllJunctions,
  fetchAllImages,
  fetchAllOnboarding,
  fetchAllTrusts,
  addPathway,
} from "../../allData";

import {
  addPage,
  addSection,
  fetchPages,
  fetchTiles,
  addTile,
  fetchPathways,
  fetchJunctionPages,
  add_junctionPage,
  fetchImages,
  addImage,
} from "../../actions";

import TrustTransfer from "./trusts";
import PageTransfer from "./pages";
import { updatePage } from "../../graphql/mutations";

const DataImport = () => {
  const dispatch = useDispatch();

  const [base, update_base] = useState(null);
  const [dropdown_options, update_dropdown_options] = useState([]);
  const [page_selected, update_page_selected] = useState(null);

  const all_pages = useSelector((state) => state.pages);

  const all_images = useSelector((state) => state.images);
  const trust_tiles = useSelector((state) => state.tiles);
  const trust_pathways = useSelector((state) => state.pathways);
  const trust_junctions = useSelector((state) => state.junctionPages);

  const [trusts, update_trusts] = useState([]);
  const [pages, update_pages] = useState([]);
  const [tiles, update_tiles] = useState([]);
  const [pathways, update_pathways] = useState([]);
  const [junctions, update_junctions] = useState([]);

  const [images, update_images] = useState([]);

  const [sections, update_sections] = useState([]);

  const [activeItem, update_activeItem] = useState("Pages");
  const [page_modal, update_page_modal] = useState(false);
  const [page_update_data, update_page_update_data] = useState({
    page_to_update: {},
    existing_page: {},
  });

  const [active_index_right, update_active_index_right] = useState(0);

  const [active_index_left, update_active_index_left] = useState(0);

  const [existing_sections, update_existing_sections] = useState([]);

  useEffect(() => {
    getTrusts();
  }, []);

  async function getTrusts() {
    console.log("testing data");
    try {
      const params = {
        action: "get_trusts",
        user_id: "base_user",
      };
      const response = await API.graphql(
        graphqlOperation(dataImporter, { meta: JSON.stringify(params) })
      );
      console.log(response);
      const trusts_to_add = JSON.parse(response.data.dataImporter).body.Items;

      const other = [
        {
          id: "common",
          trust_id: "common",
          name: "Common Pages",
        },
        {
          id: "template",
          trust_id: "template",
          name: "Template Pages",
        },
      ];
      update_trusts(trusts_to_add);
      update_dropdown_options([...trusts_to_add, ...other]);
    } catch (err) {
      console.log("error fetching data...", err);
    }
  }

  async function getPages() {
    console.log("testing data");
    dispatch(fetchPages(base));
    try {
      const params = {
        action: "get_pages",
        user_id: base,
      };
      const response = await API.graphql(
        graphqlOperation(dataImporter, { meta: JSON.stringify(params) })
      );
      console.log(JSON.parse(response.data.dataImporter).body.Items);
      update_pages(JSON.parse(response.data.dataImporter).body.Items);
    } catch (err) {
      console.log("error fetching data...", err);
    }
  }

  async function getTiles() {
    console.log("testing data");
    dispatch(fetchTiles(base));
    try {
      const params = {
        action: "get_tiles",
        user_id: base,
      };
      const response = await API.graphql(
        graphqlOperation(dataImporter, { meta: JSON.stringify(params) })
      );
      console.log(JSON.parse(response.data.dataImporter).body.Items);
      update_tiles(JSON.parse(response.data.dataImporter).body.Items);
    } catch (err) {
      console.log("error fetching data...", err);
    }
  }
  async function getPathways() {
    console.log("testing data");
    dispatch(fetchPathways(base));
    try {
      const params = {
        action: "get_pathways",
        user_id: base,
      };
      const response = await API.graphql(
        graphqlOperation(dataImporter, { meta: JSON.stringify(params) })
      );
      console.log(JSON.parse(response.data.dataImporter).body.Items);
      update_pathways(JSON.parse(response.data.dataImporter).body.Items);
    } catch (err) {
      console.log("error fetching data...", err);
    }
  }

  async function getJunctions() {
    console.log("testing data");
    dispatch(fetchJunctionPages(base));
    try {
      const params = {
        action: "get_junctions",
        user_id: base,
      };
      const response = await API.graphql(
        graphqlOperation(dataImporter, { meta: JSON.stringify(params) })
      );
      console.log(JSON.parse(response.data.dataImporter).body.Items);
      update_junctions(JSON.parse(response.data.dataImporter).body.Items);
    } catch (err) {
      console.log("error fetching data...", err);
    }
  }

  async function getImages() {
    // this is from test version
    console.log("testing data");
    dispatch(fetchImages());
    try {
      const params = {
        action: "get_images",
        user_id: base,
      };
      const response = await API.graphql(
        graphqlOperation(dataImporter, { meta: JSON.stringify(params) })
      );
      console.log(JSON.parse(response.data.dataImporter).body.Items);
      update_images(JSON.parse(response.data.dataImporter).body.Items);
    } catch (err) {
      console.log("error fetching data...", err);
    }
  }

  async function getSections(page_id) {
    console.log("getting Sections");
    try {
      const params = {
        action: "get_sections",
        user_id: page_id,
      };
      const response = await API.graphql(
        graphqlOperation(dataImporter, { meta: JSON.stringify(params) })
      );
      console.log(JSON.parse(response.data.dataImporter).body.Items);
      update_sections(JSON.parse(response.data.dataImporter).body.Items);
    } catch (err) {
      console.log("error fetching data...", err);
    }
  }

  async function getExistingSections(page_id) {
    try {
      const response = await API.graphql(
        graphqlOperation(sectionsSectionId, { section_id: page_id })
      );
      console.log(response);
      update_existing_sections(response.data.sectionsSectionId.items);
    } catch (err) {
      console.log("error fetching data...", err);
    }
  }

  function getPageAndSections(page_params) {
    update_page_selected(page_params);
    getSections(page_params.page_id);
  }

  async function addPageAndSections() {
    console.log("adding page and sections");
    console.log(page_selected);
    console.log("new_page_params.id");
    console.log(page_selected.id);

    let new_page_params = { ...page_selected };
    delete new_page_params.__typename;

    const response = await API.graphql(
      graphqlOperation(getPage, { id: page_selected.id })
    );
    console.log(response.data.getPage);
    if (response.data.getPage === null) {
      console.log("we dont have this page already so can import");
      // This imports from test to live
      try {
        try {
          delete new_page_params.createdAt;
          delete new_page_params.updatedAt;
          console.log("new_page_params.id");
          console.log(new_page_params.id);

          const response = await API.graphql(
            graphqlOperation(getPage, { id: new_page_params.id })
          );

          dispatch(addPage({ Item: new_page_params }));
        } catch (error) {
          console.log(error);
          // return;
        }
        try {
          console.log(sections);
          sections.map((section) => {
            let new_section_params = { ...section };
            delete new_section_params.__typename;
            delete new_section_params.createdAt;
            delete new_section_params.updatedAt;
            console.log(new_section_params);
            dispatch(addSection(new_section_params));
          });
        } catch (error) {}
      } catch (error) {
        console.log(error);
      }
    }
    if (response.data.getPage !== null) {
      console.log("this page is already imported");
      getExistingSections(page_selected.page_id);

      const params = {
        page_to_update: new_page_params,
        existing_page: response.data.getPage,
      };
      update_page_update_data(params);
      update_page_modal(true);
    }
    return;
  }
  async function updatePageFromTestToLive() {
    console.log("updating from test to live"); // 2. Cycle through existing live sections and check there is not a section requiring deletion that is not found in test
    // 1. update the page details.
    // 3. cycle through the test sections and 1. check exists and then 2. updates or add depending.
    // 4. close modal

    // 1. update the page details.
    try {
      let new_page_data = { ...page_selected };
      delete new_page_data.__typename;
      delete new_page_data.createdAt;
      delete new_page_data.updatedAt;

      const response = await API.graphql(
        graphqlOperation(updatePage, { input: new_page_data })
      );
      console.log(response);
    } catch (err) {
      console.log("error fetching data...", err);
    } // 2. Cycle through existing live sections and check there is not a section requiring deletion that is not found in test
    // 3. cycle through the test sections and 1. check exists and then 2. updates or add depending.
    // 4. close modal
  }

  function returnPageList() {
    return pages.map((page) => {
      var found = false;
      if (_.find(all_pages, { id: page.id })) {
        found = true;
      }

      return (
        <div
          style={
            found
              ? { cursor: "pointer", color: "green" }
              : { cursor: "pointer" }
          }
          onClick={() => getPageAndSections(page)}
        >
          {page.title}
          {found ? " ( Already Added )" : ""}
        </div>
      );
    });
  }

  function returnTileList() {
    return tiles.map((tile) => {
      var found = false;
      if (_.find(trust_tiles, { id: tile.id })) {
        found = true;
      }

      var params = { ...tile };

      delete params.__typename;
      delete params.createdAt;
      delete params.updatedAt;
      return (
        <div
          style={
            found
              ? { cursor: "pointer", color: "green" }
              : { cursor: "pointer" }
          }
          onClick={() => dispatch(addTile({ Item: params }))}
        >
          {tile.title}
          {found ? " ( Already Added )" : ""}
        </div>
      );
    });
  }

  function returnPathwayList() {
    return pathways.map((pathway) => {
      var found = false;
      if (_.find(trust_pathways, { id: pathway.id })) {
        found = true;
      }

      var params = { ...pathway };

      delete params.__typename;
      delete params.createdAt;
      delete params.updatedAt;
      return (
        <div
          style={
            found
              ? { cursor: "pointer", color: "green" }
              : { cursor: "pointer" }
          }
          onClick={() => dispatch(addPathway(params))}
        >
          {pathway.title}
          {found ? " ( Already Added )" : ""}
        </div>
      );
    });
  }
  function returnJunctionList() {
    return junctions.map((junction) => {
      var found = false;
      if (_.find(trust_junctions, { id: junction.id })) {
        found = true;
      }

      var params = { ...junction };

      delete params.__typename;
      delete params.createdAt;
      delete params.updatedAt;
      return (
        <div
          style={
            found
              ? { cursor: "pointer", color: "green" }
              : { cursor: "pointer" }
          }
          onClick={() => dispatch(add_junctionPage(params))}
        >
          {junction.title}
          {found ? " ( Already Added )" : ""}
        </div>
      );
    });
  }
  function returnImageList() {
    const image_block = images.map((image) => {
      var found = false;
      if (_.find(all_images, { id: image.id })) {
        found = true;
      }

      var params = { ...image };

      delete params.__typename;
      delete params.createdAt;
      delete params.updatedAt;
      return (
        <Grid.Column
          style={
            found
              ? { backgroundColor: "green", padding: 5, margin: 2 }
              : { backgroundColor: "white", padding: 5, margin: 2 }
          }
          onClick={() => dispatch(addImage(params))}
        >
          {/* {image.hash} */}
          <Image
            style={{ width: 200 }}
            // style={
            //   found
            //     ? { border: 5, borderColor: "green", width: 150 }
            //     : { width: 150 }
            // }
            src={image.image_url}
          />
        </Grid.Column>
      );
    });
    return (
      <Grid columns={8} padded>
        {image_block}
      </Grid>
    );
  }

  function returnSectionList() {
    return sections.map((section) => {
      return (
        <div
          style={{ cursor: "pointer" }}
          //   onClick={() => update_page_selected(section.id)}
        >
          {section.title}
        </div>
      );
    });
  }

  function mapObject(myObject) {
    const ordered = Object.keys(myObject)
      .sort()
      .reduce((obj, key) => {
        obj[key] = myObject[key];
        return obj;
      }, {});

    return Object.entries(ordered).map(([key, value]) => {
      return (
        <div>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {key} {" : "}
          </span>
          {value}
        </div>
      );
    });
  }

  function returnAccordion(sections_params, side) {
    let sections_array = _.sortBy(sections_params, ["index_number"]);

    let activeIndexIs = active_index_left;
    let update_active_index = update_active_index_left;
    if (side === "right") {
      activeIndexIs = active_index_right;
      update_active_index = update_active_index_right;
    }

    function update_active_index_function(index_params) {
      if (activeIndexIs === index_params) {
        update_active_index(-1);
        return;
      }
      update_active_index(index_params);
    }
    return (
      <Accordion styled>
        {sections_array.map((s, index) => {
          return (
            <>
              <Accordion.Title
                active={activeIndexIs === index}
                index={2}
                onClick={() => update_active_index_function(index)}
              >
                <Icon name="dropdown" />
                {s.title}
              </Accordion.Title>
              <Accordion.Content active={activeIndexIs === index}>
                {mapObject(s)}
              </Accordion.Content>
            </>
          );
        })}
      </Accordion>
    );
  }

  function returnPageModal() {
    // const params = {
    //   page_to_update: page_selected,
    //   existing_page: response.data.getPage,
    // };
    // update_page_update_data({ params });
    return (
      <Modal
        onClose={() => update_page_modal(false)}
        onOpen={() => update_page_modal(true)}
        open={page_modal}
        trigger={<Button>Show Modal</Button>}
      >
        <Modal.Header>Update Page and sections</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Grid columns="two" divided>
              <Grid.Column>
                <Header>Existing Page</Header>
                {mapObject(page_update_data.existing_page)}
                <Header>Sections</Header>
                {returnAccordion(existing_sections, "right")}
              </Grid.Column>
              <Grid.Column>
                <Header>Importing Page</Header>
                {mapObject(page_update_data.page_to_update)}
                <Header>Sections</Header>
                {returnAccordion(sections)}
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => update_page_modal(false)}>
            Cancel
          </Button>
          <Button
            content="Yes, I am sure I want to update"
            labelPosition="right"
            icon="checkmark"
            onClick={() => updatePageFromTestToLive()}
            positive
          />
        </Modal.Actions>
      </Modal>
    );
  }

  function returnMenu() {
    return (
      <Menu tabular>
        <Menu.Item
          name="Pages"
          active={activeItem === "Pages"}
          onClick={() => update_activeItem("Pages")}
        />
        <Menu.Item
          name="Tiles"
          active={activeItem === "Tiles"}
          onClick={() => update_activeItem("Tiles")}
        />
        <Menu.Item
          name="Pathways"
          active={activeItem === "Pathways"}
          onClick={() => update_activeItem("Pathways")}
        />
        <Menu.Item
          name="Junction Pages"
          active={activeItem === "Junction Pages"}
          onClick={() => update_activeItem("Junction Pages")}
        />
        <Menu.Item
          name="Images"
          active={activeItem === "Images"}
          onClick={() => update_activeItem("Images")}
        />
        <Menu.Item
          name="Trusts"
          active={activeItem === "Trusts"}
          onClick={() => update_activeItem("Trusts")}
        />
      </Menu>
    );
  }

  function returnTrustSelect() {
    return (
      <Dropdown
        placeholder="Select a trust or common / template page list"
        fluid
        selection
        options={dropdown_options.map((option) => ({
          key: option.trust_id,
          value: option.trust_id,
          text: option.name,
        }))}
        onChange={(e, { value }) => update_base(value)}
      />
    );
  }
  function returnSection() {
    switch (activeItem) {
      case "Pages":
        return (
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column>{returnPageList()}</Grid.Column>
              <Grid.Column>
                <div>{returnSectionList()}</div>
                {sections.length !== 0 ? (
                  <div style={{ marginTop: 3 }}>
                    <Button primary onClick={() => addPageAndSections()}>
                      Add Page
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
        break;
      case "Tiles":
        return (
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column>
                <div style={{ marginTop: 10 }}>
                  <h3>Click to add tile</h3>
                  {returnTileList()}
                </div>
              </Grid.Column>

              <Grid.Column>
                {/* <div>{returnSectionList()}</div> */}
                {/* <Button primary onClick={() => addPageAndSections()}>
                  Add Tile
                </Button> */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      case "Pathways":
        return (
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column>
                <div style={{ marginTop: 10 }}>
                  <h3>Click to add tile</h3>
                  {returnPathwayList()}
                </div>
              </Grid.Column>

              <Grid.Column>
                {/* <div>{returnSectionList()}</div> */}
                {/* <Button primary onClick={() => addPageAndSections()}>
                      Add Tile
                    </Button> */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      case "Junction Pages":
        return (
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column>
                <div style={{ marginTop: 10 }}>
                  <h3>Click to add junction</h3>
                  {returnJunctionList()}
                </div>
              </Grid.Column>

              <Grid.Column>
                {/* <div>{returnSectionList()}</div> */}
                {/* <Button primary onClick={() => addPageAndSections()}>
                          Add Tile
                        </Button> */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      case "Images":
        return (
          <div style={{ marginTop: 10 }}>
            <h3>Click to add image</h3>
            {returnImageList()}
          </div>
        );
      default:
        break;
    }
  }

  function returnSelections() {
    switch (activeItem) {
      case "Pages":
        return (
          <div>
            <h2>Page Transfer</h2>
            <div>
              <Segment secondary>
                <div style={{ width: "80%", display: "inline-block" }}>
                  {returnTrustSelect()}
                </div>
                <div style={{ marginLeft: 20, display: "inline-block" }}>
                  <Button primary onClick={() => getPages()}>
                    Get Pages
                  </Button>
                </div>
              </Segment>
            </div>
            <div>{returnSection()}</div>
          </div>
        );
        break;
      case "Tiles":
        return (
          <div>
            <h2>Tile Transfer</h2>
            <div>
              <Segment secondary>
                <div style={{ width: "80%", display: "inline-block" }}>
                  {returnTrustSelect()}
                </div>
                <div style={{ marginLeft: 20, display: "inline-block" }}>
                  <Button primary onClick={() => getTiles()}>
                    Get Tiles
                  </Button>
                </div>
              </Segment>
            </div>
            <div>{returnSection()}</div>
          </div>
        );
        break;
      case "Pathways":
        return (
          <div>
            <h2>Pathway Transfer</h2>
            <div>
              <Segment secondary>
                <div style={{ width: "80%", display: "inline-block" }}>
                  {returnTrustSelect()}
                </div>
                <div style={{ marginLeft: 20, display: "inline-block" }}>
                  <Button primary onClick={() => getPathways()}>
                    Get Pathways
                  </Button>
                </div>
              </Segment>
            </div>
            <div>{returnSection()}</div>
          </div>
        );
        break;
      case "Junction Pages":
        return (
          <div>
            <h2>Junction Page Transfer</h2>
            <div>
              <Segment secondary>
                <div style={{ width: "80%", display: "inline-block" }}>
                  {returnTrustSelect()}
                </div>
                <div style={{ marginLeft: 20, display: "inline-block" }}>
                  <Button primary onClick={() => getJunctions()}>
                    Get Junctions
                  </Button>
                </div>
              </Segment>
            </div>
            <div>{returnSection()}</div>
          </div>
        );
      case "Images":
        return (
          <div>
            <h2>Image Transfer</h2>
            <div>
              <Segment secondary>
                <div style={{ width: "80%", display: "inline-block" }}>
                  {returnTrustSelect()}
                </div>
                <div style={{ marginLeft: 20, display: "inline-block" }}>
                  <Button primary onClick={() => getImages()}>
                    Get Images
                  </Button>
                </div>
              </Segment>
            </div>
            <div>{returnSection()}</div>
          </div>
        );
      case "Trusts":
        return (
          <div>
            <h2>Trust Transfer</h2>
            <div>
              <TrustTransfer trusts={trusts} />
            </div>
          </div>
        );
        break;

      default:
        break;
    }
  }
  return (
    <div>
      {page_modal}
      {returnMenu()}
      {returnSelections()}
      {returnPageModal()}
      <Divider />
    </div>
  );
};

export default DataImport;
