import React from "react";
import GitInfo from "react-git-info/macro";
const Index = () => {
  // function returnGItInfo () {
  //     return <div>{branch</div>
  // }
  return (
    <div>
      <div>Repo</div>
      <div onClick={() => console.log(GitInfo())}>test</div>
    </div>
  );
};

export default Index;
