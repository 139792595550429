import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import { Button } from "semantic-ui-react";

import { addSystemInfo, updateSystemInfo } from "../../actions";
import { getOnboardingInfo } from "../../graphql/queries";

const Index = () => {
  const dispatch = useDispatch();
  const [version_number, update_version_number] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const response = await API.graphql(
      graphqlOperation(getOnboardingInfo, { id: "version" })
    );
    console.log(response.data.getOnboardingInfo);
    update_version_number(response.data.getOnboardingInfo.data);
  }

  function updateVersion() {
    var new_number = parseInt(version_number);
    new_number = new_number + 1;
    console.log(new_number);
    console.log(typeof new_number);
    var new_number_to_string = new_number.toString();
    console.log(new_number_to_string);
    console.log(typeof new_number_to_string);

    try {
      const params = {
        id: "version",
        data: new_number_to_string,
      };
      dispatch(updateSystemInfo(params));
    } catch (error) {
      console.log(error);
      const params = {
        id: "version",
        data: "1",
      };
      dispatch(addSystemInfo(params));
    }
  }
  function resetVersion() {
    const params = {
      id: "version",
      data: "1",
    };
    dispatch(updateSystemInfo(params));
  }

  return (
    <div>
      <h1>App Version</h1>
      <h3>Version Number: {version_number}</h3>
      <Button primary onClick={() => updateVersion()}>
        Update Version
      </Button>
      <Button onClick={() => resetVersion()}>Rest to V1</Button>
    </div>
  );
};

export default Index;
