import React, { useState, useEffect } from "react";
import { Menu } from "semantic-ui-react";

import Version from "../../components/version/";
import Layout from "../../components/layout";
import Users from "../../components/users";
import Trusts from "../../components/trusts/Trusts";
import Onboarding from "../../components/api";
import DataImport from "../../components/dataImport";
import Repo from "../../components/repo";

const Index = () => {
  const [activeItem, update_activeItem] = useState("trusts");

  function renderSections() {
    switch (activeItem) {
      case "trusts":
        return <Trusts />;
        break;
      case "onboarding":
        return <Onboarding />;
        break;
      // case "users":
      //   return <Users />;
      //   break;

      case "data_import":
        return <DataImport />;
      case "app":
        return <Version />;
      case "repo":
        return <Repo />;
      default:
        break;
    }
  }

  return (
    <Layout>
      <Menu>
        <Menu.Item
          name="trusts"
          active={activeItem === "trusts"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          Trusts
        </Menu.Item>
        {/* <Menu.Item
          name="users"
          active={activeItem === "users"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          Users
        </Menu.Item> */}
        <Menu.Item
          name="data_import"
          active={activeItem === "data_import"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          Data Import
        </Menu.Item>
        <Menu.Item
          name="onboarding"
          active={activeItem === "onboarding"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          Onboarding
        </Menu.Item>
        <Menu.Item
          name="app"
          active={activeItem === "app"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          App
        </Menu.Item>
        <Menu.Item
          name="repo"
          active={activeItem === "repo"}
          onClick={(e, { name }) => update_activeItem(name)}
        >
          Repo
        </Menu.Item>
      </Menu>
      {renderSections()}
    </Layout>
  );
};

export default Index;
